document.addEventListener("turbolinks:load", () =>
{
  if (document.getElementsByClassName("trix-content").length >= 1)
  {
    const trixContent = document.getElementsByClassName("trix-content");
    let content = trixContent[0].innerHTML;

    if (content.includes("[CTA_BUTTON]"))
    {
      let newContent = content.replace("[CTA_BUTTON]",
        `
          <div class="text-center mt-8">
            <a
              href="https://app1.edoobox.com/de/LE-KIH/Kin%C3%A4sthetik%20Infant%20Handling%20Kurse%202020/"
              class="cta-button"
              target="_blank"
              rel="noopener noreferrer"
            >
            Jetzt kommende Kurse ansehen
            </a>
          </div>
        `
      );
      trixContent[0].innerHTML = newContent;
    }
  }
});
